import React, {useMemo, useState} from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import WizIconButton from '@component/buttons/WizIconButton'
import IconClose from '@svg/common/ic_close.svg'
import IconMainBannerFaq from '@svg/leaderboard/ic_main_banner_faq.svg'
import IconCopyBannerFaq from '@svg/leaderboard/ic_copy_banner_faq.svg'
import Link from 'next/link'
import {TIME_DAY} from '@constant/time'
import {createLog} from '@util/logs'
import {getLocalStorage} from '@util/localstorage'

interface IProps {
    className?: string
    type: 'copy' | 'main'
}

export const KEY__NEVER_SHOW__WEEKLY__LEADERBOARD_COPY_FAQ_BANNER =
    'KEY__NEVER_SHOW__WEEKLY__LEADERBOARD_COPY_FAQ_BANNER'
export const KEY__NEVER_SHOW__WEEKLY__LEADERBOARD_MAIN_FAQ_BANNER =
    'KEY__NEVER_SHOW__WEEKLY__LEADERBOARD_MAIN_FAQ_BANNER'
const LeaderboardFaqBanner: React.FC<IProps> = ({className, type}) => {
    const {t} = useTranslation()
    const objString = getLocalStorage(
        type === 'copy'
            ? KEY__NEVER_SHOW__WEEKLY__LEADERBOARD_COPY_FAQ_BANNER
            : KEY__NEVER_SHOW__WEEKLY__LEADERBOARD_MAIN_FAQ_BANNER,
    )
    const [isHide, setIsHide] = useState(!objString ? false : !(objString && Date.now() > objString?.expire))

    const onClose = () => {
        createLog('event', 'leaderboard_faq_banner_close', {type})
        localStorage?.setItem(
            type === 'copy'
                ? KEY__NEVER_SHOW__WEEKLY__LEADERBOARD_COPY_FAQ_BANNER
                : KEY__NEVER_SHOW__WEEKLY__LEADERBOARD_MAIN_FAQ_BANNER,
            JSON.stringify({
                value: 'true',
                expire: Date.now() + TIME_DAY * 1000 * 7,
            }),
        )
        setIsHide(true)
    }

    const getStyle = useMemo(() => {
        switch (type) {
            case 'main':
                return 'bg-primary_tint01 dark:bg-dark_primary_tint01'
            case 'copy':
                return 'bg-blue_tint01 dark:bg-dark_blue_tint01'
        }
    }, [type])

    return (
        <>
            {!isHide && (
                <div
                    data-cy={'banner_leaderboard_copy_faq'}
                    className={`px-[30px] sm:px-[30px] sm:py-[18px] flex items-center rounded-[3px] ${getStyle} ${className} min-h-[60px]`}>
                    <div
                        className={'flex items-center justify-center flex-1 sm:flex-col sm:gap-y-[5px] sm:items-start'}>
                        {type === 'main' ? (
                            <IconMainBannerFaq className={'flex-none sm:hidden'} />
                        ) : (
                            <IconCopyBannerFaq className={'flex-none sm:hidden'} />
                        )}
                        <Text className={'relative text-gray01 dark:text-dark_gray01 z-10 pl-[10px] sm:pl-0'}>
                            {type === 'main'
                                ? t('mainLeaderLeaderboard.FaqBanner.text')
                                : t('copyLeaderLeaderboard.copyTradingFaqBanner.text')}
                        </Text>
                        <div className={'flex ml-[20px] sm:ml-0'}>
                            <Link
                                target={'_blank'}
                                onClick={() => createLog('event', 'leaderboard_faq_banner_click', {type})}
                                href={
                                    type === 'main'
                                        ? t('mainLeaderLeaderboard.FaqBanner.faq.linkUrl')
                                        : t('copyLeaderLeaderboard.copyTradingFaqBanner.faq.linkUrl')
                                }>
                                <Text
                                    className={
                                        'text-body2 underline text-gray02 dark:text-dark_gray02 hover:text-primary_shade dark:hover:text-dark_primary_shade'
                                    }>
                                    {type === 'main'
                                        ? t('mainLeaderLeaderboard.FaqBanner.faq.text')
                                        : t('copyLeaderLeaderboard.copyTradingFaqBanner.faq.text')}
                                </Text>
                            </Link>
                        </div>
                    </div>
                    <WizIconButton
                        onClick={onClose}
                        className={'z-[10] hover:bg-transparent'}
                        dataCy={'btn_leaderboard_copy_faqClose'}>
                        <IconClose className={'w-[14px] fill-gray02 dark:fill-dark_gray02'} />
                    </WizIconButton>
                </div>
            )}
        </>
    )
}

export default React.memo(LeaderboardFaqBanner)
