import React, {useCallback, useMemo, useState} from 'react'
import useNavigation from '@hook/useNavigation'
import LeagueSideListItem from '@feature/leaderboard/components/LeagueSideList/LeagueSideListItem/LeagueSideListItem'
import Text from '@component/Text/Text'
import LeagueSelect from '@feature/leaderboard/components/LeagueSideList/LeagueSelect/LeagueSelect'
import {useQueryGetShowLeagueSidebar} from '@hook/query/rank/league/useQueryGetShowLeagueSidebar'
import {useQueryGetPreviousLeagueSidebar} from '@hook/query/rank/league/useQueryGetPreviousLeagueSidebar'
import {useTranslation} from 'next-i18next'
import IconDown from '@svg/common/ic_btn_down.svg'
import {useRouter} from 'next/router'
import {ILeague} from '@api/rank/league/league'
import {createLog} from '@util/logs'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'
import SkeletonLeagueSideList from '@component/skeleton/feature/SkeletonLeagueSideList'

export type LeagueSidebarType = 'ongoing' | 'previous'

interface IProps {
    onChangedLeague: (leagueId: string) => void
    leagueId?: string
    state: LeagueSidebarType
}

const LeagueSideList: React.FC<IProps> = ({onChangedLeague, leagueId, state}) => {
    const {t} = useTranslation()
    const {query} = useRouter()
    const {
        data: leagues,
        isLoading: isShowLoading,
        isFetching: isShowFetching,
        isSuccess: isShowSuccess,
    } = useQueryGetShowLeagueSidebar()
    const {
        data: data,
        fetchNextPage,
        isReachingEnd,
        isLoading,
        isFetching,
        isSuccess,
    } = useQueryGetPreviousLeagueSidebar()
    const [onGoingCount, setOnGoingCount] = useState(0)
    const [previousCount, setPreviousCount] = useState(0)

    const [renderState, setRenderState] = useState(state)

    const onLeagueClick = useCallback(
        (leagueId: string) => {
            onChangedLeague(leagueId)
            window.scrollTo({top: 0})
        },
        [onChangedLeague],
    )

    const showLeagues = useMemo(() => {
        return leagues?.leagues?.sort((a, b) => b?.show - a?.show)
    }, [leagues])

    const previousLeagues = useMemo(() => {
        const list: ILeague[] = []
        data?.pages?.map(page => page?.leagues?.map(item => list?.push(item)))
        return list
    }, [data])

    const {goLeaderboard} = useNavigation()

    const getMoreCount = useMemo(() => {
        if (previousCount) {
            if (previousCount - previousLeagues?.length > 30) {
                return 30
            } else {
                return previousCount - previousLeagues?.length > 0 ? previousCount - previousLeagues?.length : 0
            }
        } else {
            return 0
        }
    }, [previousCount, previousLeagues])

    useIsomorphicLayoutEffect(() => {
        if (query?.league && !leagues?.leagues?.find(l => l?.id === query?.league)) {
            setRenderState('previous')
        } else {
            if (leagues?.leagues?.find(l => l?.id === leagueId)) {
                setRenderState('ongoing')
            } else if (previousLeagues?.find(l => l?.id === leagueId)) {
                setRenderState('previous')
            } else {
                setRenderState(state)
            }
        }
    }, [leagueId, leagues, previousLeagues])

    useIsomorphicLayoutEffect(() => {
        setOnGoingCount(leagues?.count)
        setPreviousCount(data?.pages?.at(0)?.count)
    }, [leagues, data])

    const onClickSetRenderState = (state: LeagueSidebarType) => {
        const path = state === 'ongoing' ? 'league' : 'league-all'
        goLeaderboard(path)
        setRenderState(state)
        createLog('event', 'leagues_sidebar_click_state', {state: state})
    }

    return (
        <div>
            <div className={'flex flex-col gap-y-[10px] lg:hidden'}>
                <div>
                    <div
                        onClick={() => onClickSetRenderState('ongoing')}
                        className={`${
                            renderState === 'ongoing'
                                ? 'bg-indigo dark:bg-dark_indigo'
                                : 'bg-indigo_tint02 dark:bg-dark_indigo_tint02'
                        } px-[20px] py-[18px] flex gap-x-[5px] justify-between items-center rounded-[5px] cursor-pointer`}>
                        <Text
                            className={`text-ti1 ${
                                renderState === 'ongoing'
                                    ? 'text-white'
                                    : 'text-indigo_shade dark:text-dark_indigo_shade'
                            }`}>
                            {t('league.leaderboard.sidebar.ongoing')}
                        </Text>
                        <div>
                            <Text
                                className={`text-ti3 ${
                                    renderState === 'ongoing'
                                        ? 'text-indigo dark:text-dark_indigo bg-white'
                                        : 'text-white !bg-indigo dark:bg-dark_indigo'
                                } px-[10px] py-[2px] rounded-[12px] bg-white text-center`}>
                                {onGoingCount || '-'}
                            </Text>
                        </div>
                    </div>
                    {renderState === 'ongoing' && (
                        <div className={'mt-[3px]'}>
                            {isShowLoading && isShowFetching && !isShowSuccess ? (
                                <SkeletonLeagueSideList length={2} />
                            ) : (
                                showLeagues?.map(league => (
                                    <LeagueSideListItem
                                        key={league.id}
                                        league={league}
                                        onClick={onLeagueClick}
                                        leagueId={leagueId}
                                    />
                                ))
                            )}
                        </div>
                    )}
                </div>
                <div
                    onClick={() => onClickSetRenderState('previous')}
                    className={`${
                        renderState === 'previous'
                            ? 'bg-indigo dark:bg-dark_indigo'
                            : 'bg-indigo_tint02 dark:bg-dark_indigo_tint02'
                    } px-[20px] py-[18px] flex gap-x-[5px] justify-between items-center rounded-[5px] cursor-pointer`}>
                    <Text
                        className={`text-ti1 ${
                            renderState === 'previous' ? 'text-white' : 'text-indigo_shade dark:text-dark_indigo_shade'
                        }`}>
                        {t('league.leaderboard.sidebar.previous')}
                    </Text>
                    <div>
                        <Text
                            className={`text-ti3 ${
                                renderState === 'previous'
                                    ? 'text-indigo dark:text-dark_indigo bg-white'
                                    : 'text-white bg-indigo dark:bg-dark_indigo'
                            } px-[10px] py-[2px] rounded-[12px] text-center`}>
                            {previousCount || '-'}
                        </Text>
                    </div>
                </div>
                {renderState === 'previous' && (
                    <div className={'mt-[3px]'}>
                        {isLoading && isFetching && !isSuccess ? (
                            <SkeletonLeagueSideList />
                        ) : (
                            previousLeagues?.map(league => (
                                <LeagueSideListItem
                                    key={league.id}
                                    league={league}
                                    onClick={onLeagueClick}
                                    leagueId={leagueId}
                                    isUseTag={false}
                                />
                            ))
                        )}

                        {isLoading && isFetching && !isSuccess && !isReachingEnd && (
                            <div
                                onClick={() => {
                                    createLog('event', 'leaderboard_league_bottom_sheet_more_click')
                                    fetchNextPage()
                                }}
                                className={
                                    'flex items-center justify-center mt-[10px] p-[3px] gap-x-[5px]  cursor-pointer'
                                }>
                                <Text className={'text-ti3 text-gray02 dark:text-dark_gray02'}>
                                    {t('leaderboard.bottomsheet.more', {count: getMoreCount})}
                                </Text>
                                <IconDown width={8} className={'fill-gray02 dark:fill-dark_gray02'} />
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className={'hidden lg:block lg:mb-[20px]'}>
                <LeagueSelect
                    leagueId={leagueId}
                    onClick={e => {
                        createLog('event', 'leaderboard_sidebar_click_league', {league: e})
                        onChangedLeague(e)
                    }}
                />
            </div>
        </div>
    )
}

export default LeagueSideList
