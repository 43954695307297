import React from 'react'
import Text from '@component/Text/Text'
import IconStar from '@svg/common/ic_star.svg'

interface Props {
    title: string
    isSelected: boolean
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    disabled?: boolean
    className?: string
}

const FavoriteFilterButton = ({title, isSelected, onClick, disabled = false, className}: Props) => {
    return (
        <div
            className={`rounded-[3px] ring-[1px] ring-gray06 w-min h-min dark:ring-dark_gray06 cursor-pointer bg-white dark:bg-bg_dark_white01 ${className}`}
            onClick={e => {
                if (disabled) {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                }
                onClick && onClick(e)
            }}>
            <div className={'py-[12px] px-[20px] flex justify-center items-center gap-x-[10px] '}>
                <IconStar className={`w-[20px] ${isSelected ? 'fill-yellow' : 'fill-gray05 dark:fill-dark_gray05'}`} />
                <Text
                    className={`text-body1 leading-[21px] ${
                        isSelected ? 'text-gray01 dark:text-dark_gray01' : 'text-gray01 dark:text-dark_gray01'
                    }`}>
                    {title}
                </Text>
            </div>
        </div>
    )
}

export default FavoriteFilterButton
