import React, {useMemo} from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
    id?: string
    currentLeagueId?: string
    end_at?: number
    start_at?: number
    type?: 'list' | 'board'
}

type leagueDateType = 'ended' | 'live' | 'soon'

const LeagueLiveTag: React.FC<IProps> = ({className, id, end_at, currentLeagueId, start_at, type}) => {
    const {t} = useTranslation()

    const leagueStatus: leagueDateType = useMemo(() => {
        if (end_at * 1000 < new Date().getTime()) {
            return 'ended'
        } else if (start_at * 1000 < new Date().getTime() && end_at * 1000 > new Date().getTime()) {
            return 'live'
        } else {
            return 'soon'
        }
    }, [end_at, start_at])

    const getStyleDataTag = useMemo(() => {
        switch (leagueStatus) {
            case 'ended':
                if (currentLeagueId === id) {
                    return 'bg-gray06 dark:bg-dark_gray06 border-gray06 dark:border-dark_gray06 text-gray02 dark:text-dark_gray02'
                } else {
                    return 'border-gray06 dark:border-dark_gray06 text-gray02 dark:text-dark_gray02 dark:bg-transparent'
                }

            case 'live':
                if (currentLeagueId === id) {
                    return 'bg-red_shade dark:bg-dark_red_shade border-red_shade dark:border-dark_red_shade text-white pl-[12px]'
                } else {
                    return 'dark:bg-transparent border-red_shade dark:border-dark_red_shade text-red_shade dark:text-dark_red_shade pl-[12px]'
                }
            case 'soon':
                if (currentLeagueId === id) {
                    return 'bg-indigo dark:bg-dark_indigo border-indigo dark:border-dark_indigo text-white'
                } else {
                    return 'border-indigo dark:border-dark_indigo text-indigo dark:text-dark_indigo dark:bg-transparent'
                }
        }
    }, [leagueStatus, id, currentLeagueId])

    return (
        <div
            className={`flex justify-center relative px-[5px] py-[0.5px] border rounded-[10px] w-min ${getStyleDataTag} ${className}`}>
            {leagueStatus === 'live' && (
                <div
                    className={`absolute left-[5px] top-[50%] translate-y-[-60%] ${
                        currentLeagueId === id
                            ? 'w-[4px] h-[4px] rounded-full bg-white mr-[3px]'
                            : `w-[5px] h-[5px] rounded-full bg-red_shade dark:bg-dark_red_shade bg-opacity-[0.15] after:content-[''] after:w-[3px] after:h-[3px] after:block after:bg-red dark:after:bg-dark_red after:rounded-full after:absolute after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%]`
                    } `}
                />
            )}
            <Text enablePreWhiteSpace={false} className={`text-body_comm h-[15px] leading-[15px] whitespace-nowrap`}>
                {t(`leagueProgressTag.${leagueStatus}.text`)}
            </Text>
        </div>
    )
}

export default LeagueLiveTag
