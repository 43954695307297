import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react'
import Text from '@component/Text/Text'
import HorizontalDivider from '@component/divider/HorizontalDivider'
import WizContentBottomSheet from '@component/contentBottomsheet/WizContentBottomSheet'
import {useQueryGetShowLeagueSidebar} from '@hook/query/rank/league/useQueryGetShowLeagueSidebar'
import {useQueryGetPreviousLeagueSidebar} from '@hook/query/rank/league/useQueryGetPreviousLeagueSidebar'
import {useTranslation} from 'next-i18next'
import LeagueLiveTag from '@feature/leaderboard/pages/ScreenLeague/LeagueLiveTag/LeagueLiveTag'
import {ILeague} from '@api/rank/league/league'
import IconDown from '@svg/common/ic_btn_down.svg'
import LeagueIcon from '@component/images/LeagueIcon'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
    data?: ILeague
    onClick?: (e) => void
}

const LeagueBottomSheet = ({className, data, onClick}: IProps, ref) => {
    const {t} = useTranslation()
    const modalRef = useRef<any>()

    const [onGoingCount, setOnGoingCount] = useState(0)
    const [previousCount, setPreviousCount] = useState(0)

    const {data: showLeagues} = useQueryGetShowLeagueSidebar()
    const {
        data: previousLeagues,
        refetch,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        isReachingEnd,
    } = useQueryGetPreviousLeagueSidebar()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    const previousLeaguesList = useMemo(() => {
        const list: ILeague[] = []
        previousLeagues?.pages?.map(page => page?.leagues?.map(item => list?.push(item)))
        return list
    }, [previousLeagues])

    const getMoreCount = useMemo(() => {
        if (previousCount) {
            if (previousCount - previousLeaguesList?.length > 30) {
                return 30
            } else {
                return previousCount - previousLeaguesList?.length ? previousCount - previousLeaguesList?.length : 0
            }
        } else {
            return 0
        }
    }, [previousCount, previousLeaguesList])

    useIsomorphicLayoutEffect(() => {
        setOnGoingCount(showLeagues?.count)
        setPreviousCount(previousLeagues?.pages?.at(0)?.count)
    }, [showLeagues, previousLeagues])

    return (
        <WizContentBottomSheet ref={modalRef} title={t('leaderboard.bottomsheet.title')}>
            <div className={'border-t border-gray06 dark:border-dark_gray06 pt-[20px]'}>
                <div className={'overflow-y-auto max-h-[65vh]'}>
                    <div className={''}>
                        <div className={'flex items-center gap-x-[10px] py-[5px]'}>
                            <Text
                                enablePreWhiteSpace={false}
                                className={'text-ti2 whitespace-nowrap text-gray01 dark:text-dark_gray01'}>
                                {t('leaderboard.bottomsheet.ongoing')}({onGoingCount})
                            </Text>
                            <HorizontalDivider className={'bg-gray07 dark:bg-dark_gray07'} />
                        </div>
                        {showLeagues?.leagues?.map(item => (
                            <div
                                key={item?.id}
                                onClick={() => {
                                    onClick(item?.id)
                                    modalRef?.current?.close()
                                }}
                                className={`flex items-center gap-x-[10px] p-[10px] cursor-pointer ${
                                    data?.id === item?.id ? 'bg-gray09 dark:bg-dark_gray09' : ''
                                }`}>
                                <LeagueLiveTag
                                    currentLeagueId={data?.id}
                                    id={item?.id}
                                    start_at={item?.start_at}
                                    end_at={item?.end_at}
                                />
                                <Text className={'flex-1 text-body2 text-gray03 dark:text-dark_gray03'}>
                                    {item?.name}
                                </Text>
                            </div>
                        ))}
                    </div>
                    <div className={'pt-[20px]'}>
                        <div className={'flex items-center gap-x-[10px] py-[5px]'}>
                            <Text
                                enablePreWhiteSpace={false}
                                className={'text-ti2 whitespace-nowrap text-gray01 dark:text-dark_gray01'}>
                                {t('leaderboard.bottomsheet.previous')}({previousCount})
                            </Text>
                            <HorizontalDivider className={'bg-gray07 dark:bg-dark_gray07'} />
                        </div>
                        {previousLeaguesList?.map(item => (
                            <div
                                key={item?.id}
                                onClick={() => {
                                    onClick(item?.id)
                                    modalRef?.current?.close()
                                }}
                                className={`flex items-center gap-x-[10px] p-[10px] cursor-pointer  ${
                                    data?.id === item?.id ? 'bg-gray09 dark:bg-dark_gray09' : ''
                                }`}>
                                <LeagueIcon src={item?.icon} size={16} />
                                <Text className={`flex-1 text-body2 text-gray03 dark:text-dark_gray03`}>
                                    {item?.name}
                                </Text>
                            </div>
                        ))}
                        {!isReachingEnd && (
                            <div
                                onClick={() => {
                                    createLog('event', 'leaderboard_league_bottom_sheet_more_click')
                                    fetchNextPage()
                                }}
                                className={
                                    'flex items-center justify-center mt-[10px] p-[3px] gap-x-[5px] cursor-pointer'
                                }>
                                <Text className={'text-ti3 text-gray02 dark:text-dark_gray02'}>
                                    {t('leaderboard.bottomsheet.more', {count: getMoreCount})}
                                </Text>
                                <IconDown width={8} className={'fill-gray02 dark:fill-dark_gray02'} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </WizContentBottomSheet>
    )
}

export default React.forwardRef(LeagueBottomSheet)
