import React, {useRef, useState} from 'react'
import Text from '@component/Text/Text'
import IconClock from '@svg/common/ic_clock.svg'
import IconNotice from '@svg/common/ic_notice.svg'
import {apiGetSearchUser, ISearchUser} from '@api/leaderboard/search/getSearchUser'
import SearchUserItem from '@feature/leaderboard/components/SearchModal/SearchUserItem'
import {useSearchUserHistoryStore} from '@store/SearchUserHistoryStore'
import {isNotEmpty} from '@util/strings'
import {useTranslation} from 'next-i18next'
import SearchRecentUserItem from '@feature/leaderboard/components/SearchModal/SearchRecentUserItem'
import WizPopupModal from '@component/modal/WizPopupModal'
import IconSearch from '@svg/common/ic_search.svg'
import IconModalClose from '@svg/common/ic_modal_close.svg'
import WizIcon from '@component/images/WizIcon'
import {useRouter} from 'next/router'
import IconMobileClose from '@svg/gnb/ic_mo_close.svg'

const SearchModal = (props: any, modalRef: any) => {
    const {t} = useTranslation()
    const [searchList, setSearchList] = useState<Array<ISearchUser>>([])
    const [historyList, setHistoryList] = useState<Array<ISearchUser>>([])
    const inputRef = useRef(null)
    const router = useRouter()
    const {getRecentUsers, removeRecentUserAll} = useSearchUserHistoryStore()

    const fetchSearchUser = async text => {
        if (text) {
            const {data} = await apiGetSearchUser(text)
            setSearchList(data ?? [])
        } else {
            setSearchList([])
        }
    }

    const handleClose = () => {
        setSearchList([])
    }

    const onClickCloseModal = () => {
        modalRef.current.close()
    }

    const SearchContentArea = () => {
        if (inputRef.current?.value?.length > 0 && searchList?.length > 0) {
            return (
                <>
                    {searchList?.map(item => {
                        return <SearchUserItem {...item} key={item.id} closeModal={onClickCloseModal} />
                    })}
                </>
            )
        }

        const inputText = inputRef.current?.value
        if (isNotEmpty(inputText) && searchList?.length === 0) {
            return (
                <div className={'min-h-[100px] flex justify-center items-center overflow-hidden space-x-[10px]'}>
                    <IconNotice width={24} className={'fill-gray02 dark:fill-dark_gray02 flex-none'} />
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 break-all'}>
                        {t('modal.searchUser.empty.text', {keyword: inputRef.current?.value})}
                    </Text>
                </div>
            )
        }
    }

    const SearchHistoryArea = () => {
        const inputText = inputRef.current?.value
        if (
            (inputText?.length > 0 && searchList?.length !== 0) ||
            (inputText?.length > 0 && getRecentUsers()?.length === 0)
        ) {
            return null
        }
        if (getRecentUsers()?.length === 0) {
            return (
                <div className={'min-h-[100px] flex justify-center items-center overflow-hidden space-x-[10px]'}>
                    <IconClock className={'w-[16px] flex-none fill-gray01 dark:fill-dark_gray01'} />
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.searchUser.recentUser.empty.text')}
                    </Text>
                </div>
            )
        }

        return (
            <div>
                <div className={'flex justify-between py-[10px]'}>
                    <Text className={'flex-1 text-body3 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.searchUser.recentUser.list')}
                    </Text>
                    <Text
                        onClick={() => {
                            removeRecentUserAll()
                        }}
                        className={'text-body_comm text-gray02 dark:text-dark_gray02 underline cursor-pointer'}>
                        {t('modal.searchUser.allRemove')}
                    </Text>
                </div>
                {getRecentUsers()
                    ?.slice(0, 5)
                    .map(item => {
                        return <SearchRecentUserItem {...item} key={item?.id} closeModal={onClickCloseModal} />
                    })}
            </div>
        )
    }

    const getInputStyle = () => {
        const roundedStyle = 'rounded-[33px] sm:rounded-[25px]'
        const paddingStyle = 'px-[30px] py-[20px] sm:px-[20px] sm:py-[11.5px]'
        return `bg-gray09 dark:bg-bg_dark_white02 ${paddingStyle} ${roundedStyle} grow flex items-center border border-gray07 dark:border-dark_gray07 focus-within:border-black focus-within:shadow gap-x-[10px]`
    }

    return (
        <WizPopupModal
            ref={modalRef}
            hideTitle={true}
            size={'800'}
            onCloseCallback={handleClose}
            popupClassName={'flex-1 sm:w-[100%] sm:h-[100%]'}>
            <div className={'flex flex-col'}>
                <div className={'flex items-center space-x-[20px] sm:space-x-[10px]'}>
                    <div className={`${getInputStyle()} overflow-hidden`}>
                        <IconSearch className={'w-[16px] fill-gray02 dark:fill-dark_gray02'} />
                        <input
                            ref={inputRef}
                            type={'text'}
                            className={
                                'grow text-input text-gray01 dark:text-dark_gray01 bg-transparent truncate placeholder:text-gray02 dark:placeholder:text-dark_gray02'
                            }
                            placeholder={t('modal.searchUser.input.placeholder')}
                            onChange={async e => await fetchSearchUser(e.target.value)}
                        />
                    </div>
                    <WizIcon
                        onClick={onClickCloseModal}
                        backgroundColorClassName={
                            'fill-gray02 dark:fill-dark_gray02 hover:fill-gray01 dark:hover:fill-dark_gray01 hover:bg-transparent01 dark:hover:bg-dark_transparent01 cursor-pointer'
                        }>
                        <IconModalClose className={'w-[20px] fill-gray02 dark:fill-dark_gray02 sm:hidden'} />

                        <IconMobileClose className={'w-[24px] fill-gray01 dark:fill-dark_gray01 hidden sm:block'} />
                    </WizIcon>
                </div>
                <div className={'mt-[10px] max-h-[500px] overflow-y-auto not_bg_scrollbar'}>
                    <SearchContentArea />
                </div>
                <div>
                    <SearchHistoryArea />
                </div>
            </div>
        </WizPopupModal>
    )
}

export default React.forwardRef(SearchModal)
