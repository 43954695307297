import React from 'react'
import Text from '@component/Text/Text'
import IconNoData from '@svg/common/ic_nodata.svg'
import {useTranslation} from 'next-i18next'
import WizIcon from '@component/images/WizIcon'

interface IProps {
    className?: string
    text?: string
}

const EmptyCell: React.FC<IProps> = ({className, text}) => {
    const {t} = useTranslation()
    return (
        <div className={`space-y-[10px] py-[30px] text-center ${className}`}>
            <WizIcon className={'p-[15px] w-min mx-auto'} backgroundColorType={'gray'}>
                <IconNoData className={'w-[40px] fill-gray02 dark:fill-dark_gray02'} />
            </WizIcon>
            <Text className={'text-gray02 dark:text-dark_gray02'}>{text ?? t('common.table.empty.text')}</Text>
        </div>
    )
}

export default EmptyCell
