import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import {useQueryLeagueDetail} from '@feature/leaderboard/hooks/useQueryLeagueDetail'
import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import IconArrow from '@svg/common/ic_select_arrow.svg'
import LeagueBottomSheet from '@feature/leaderboard/components/LeagueBottomSheet/LeagueBottomSheet'
import useWindowSize from '@hook/useWindowSize'

interface IProps {
    className?: string
    leagueId?: string
    onClick?: (e) => void
}

const LeagueSelect: React.FC<IProps> = ({className, leagueId, onClick}) => {
    const {t} = useTranslation()
    const {data, refetch} = useQueryLeagueDetail(leagueId)
    const modalRef = useRef<any>()

    const {isLg} = useWindowSize()

    useEffect(() => {
        if (!isLg) {
            modalRef?.current?.close()
        }
    }, [isLg])

    return (
        <>
            <div
                onClick={() => modalRef?.current?.show()}
                className={
                    'flex items-center gap-x-[10px] px-[15px] py-[10.5px] border border-gray06 dark:border-dark_gray06 rounded-[3px] cursor-pointer'
                }>
                <div>
                    <WizImage src={data?.icon} width={24} height={24} />
                </div>
                <Text
                    enablePreWhiteSpace={false}
                    className={
                        'text-body1 text-gray01 dark:text-dark_gray01 w-[40vw] whitespace-nowrap truncate flex-1'
                    }>
                    {data?.name}
                </Text>
                <IconArrow width={16} className={'fill-gray01 dark:fill-dark_gray01'} />
            </div>
            <LeagueBottomSheet data={data} onClick={onClick} ref={modalRef} />
        </>
    )
}

export default LeagueSelect
