import React from 'react'
import Text from '@component/Text/Text'
import {useRouter} from 'next/router'
import LeagueLiveTag from '@feature/leaderboard/pages/ScreenLeague/LeagueLiveTag/LeagueLiveTag'
import {ILeague} from '@api/rank/league/league'
import LeagueIcon from '@component/images/LeagueIcon'
import IconNext from '@svg/common/ic_btn_next.svg'

interface IProps {
    onClick?: (leagueId?: string) => void
    leagueId?: string
    league?: ILeague
    isUseTag?: boolean
}

const LeagueSideListItem: React.FC<IProps> = ({onClick, leagueId, league, isUseTag = true}) => {
    const router = useRouter()

    return (
        <div
            key={league?.id}
            className={`flex cursor-pointer py-[15px] px-[20px] gap-x-[10px] items-center rounded-[5px] ${
                leagueId === league?.id && 'bg-gray09 dark:bg-dark_gray09'
            }`}
            onClick={() => onClick(league?.id)}>
            {isUseTag ? (
                <LeagueLiveTag
                    currentLeagueId={leagueId}
                    id={league?.id}
                    end_at={league?.end_at}
                    start_at={league?.start_at}
                    type={'list'}
                />
            ) : (
                <LeagueIcon src={league?.icon} size={16} />
            )}
            <Text
                className={`flex-1 ${
                    leagueId === league?.id
                        ? 'text-ti2 text-gray01 dark:text-dark_gray01'
                        : 'text-body2 text-gray03 dark:text-dark_gray03'
                }`}>
                {league?.name}
            </Text>
            {leagueId === league?.id && <IconNext className={'w-[16px] fill-gray01 dark:fill-dark_gray01'} />}
        </div>
    )
}

export default LeagueSideListItem
