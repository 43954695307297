import React from 'react'
import UserThumbnail from '@component/images/UserThumbnail'
import Text from '@component/Text/Text'
import {ISearchUser} from '@api/leaderboard/search/getSearchUser'
import IconClock from '@svg/common/ic_clock.svg'
import {useSearchUserHistoryStore} from '@store/SearchUserHistoryStore'
import CloseIcon from '@svg/common/ic_close_bold.svg'
import WizIconButton from '@component/buttons/WizIconButton'
import Link from 'next/link'
import {getLinkUrl, URL_KEY_PORTFOLIO, URL_PORTFOLIO} from '@constant/url'
import CopyLeaderTag from '@component/tags/CopyLeaderTag'
import UseQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import WizImage from '@component/images/WizImage'

interface IProps extends ISearchUser {
    closeModal?: () => void
}

const SearchUserItem: React.FC<IProps> = ({id, thumbnail, name, is_leader, exchange, closeModal}) => {
    const {saveRecentUser, removeRecentUser} = useSearchUserHistoryStore()
    const {getExchange} = UseQueryGetExchanges()

    const onClickSave = () => {
        saveRecentUser({id: id, name: name, thumbnail: thumbnail, is_leader: is_leader, exchange: exchange})
    }

    const onClickRemove = e => {
        removeRecentUser(id)
        if (e?.cancelable) {
            e.preventDefault()
        }
        e.stopPropagation()
    }
    return (
        <Link href={is_leader ? getLinkUrl(URL_KEY_PORTFOLIO(id)) : getLinkUrl(URL_PORTFOLIO(id))}>
            <div
                className={'py-[10px] gap-x-[10px] flex rounded-[5px] items-center cursor-pointer truncate'}
                onClick={onClickSave}>
                <div className={'p-[5px]'}>
                    <IconClock className={'flex-none w-[14px] fill-gray01 dark:fill-dark_gray01'} />
                </div>
                <UserThumbnail src={thumbnail} size={28} />
                <div className={'flex-1 truncate'}>
                    <Text
                        enablePreWhiteSpace={false}
                        className={
                            'text-body2 text-gray01 dark:text-dark_gray01 basis-[85%] truncate whitespace-nowrap'
                        }>
                        {name}
                    </Text>
                    {is_leader === true && (
                        <div className={'flex gap-x-[5px] items-center'}>
                            <WizImage src={getExchange(exchange)?.icon} width={16} height={16} />
                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>{exchange}</Text>
                        </div>
                    )}
                </div>
                {is_leader === true && <CopyLeaderTag />}
                <WizIconButton onClick={onClickRemove} showHover={false} className={'p-[4px]'}>
                    <CloseIcon width={16} heigth={16} className={'fill-gray02 dark:fill-dark_gray02'} />
                </WizIconButton>
            </div>
        </Link>
    )
}

export default SearchUserItem
