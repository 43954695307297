import {useQuery} from '@tanstack/react-query'
import {apiGetLeagueDetail} from '@api/rank/league/getLeagueDetail'
import {isNotEmpty} from '@util/strings'
import {useRouter} from 'next/router'

export const useQueryLeagueDetail = (leagueID: string, enabled: boolean = false) => {
    const router = useRouter()

    const {data, refetch, isLoading, isFetching, isSuccess} = useQuery({
        enabled: isNotEmpty(leagueID),
        queryKey: ['league', leagueID, router?.locale],
        staleTime: 120 * 1000,
        cacheTime: 120 * 1000,
        refetchOnWindowFocus: false,
        retry: true,
        retryDelay: 2000,
        queryFn: async () => {
            const {data} = await apiGetLeagueDetail(leagueID, router?.locale)
            return data
        },
    })

    return {data, refetch, isLoading, isFetching, isSuccess}
}
