import React from 'react'
import UserThumbnail from '@component/images/UserThumbnail'
import Text from '@component/Text/Text'
import {ISearchUser} from '@api/leaderboard/search/getSearchUser'
import {useSearchUserHistoryStore} from '@store/SearchUserHistoryStore'
import CopyLeaderTag from '@component/tags/CopyLeaderTag'
import {createLog} from '@util/logs'
import Link from 'next/link'
import {getLinkUrl, URL_KEY_PORTFOLIO, URL_PORTFOLIO} from '@constant/url'
import WizImage from '@component/images/WizImage'
import UseQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'

interface IProps extends ISearchUser {
    closeModal?: () => void
}

const SearchUserItem: React.FC<IProps> = ({id, thumbnail, name, is_leader, exchange, closeModal}) => {
    const {saveRecentUser} = useSearchUserHistoryStore()
    const {getExchange} = UseQueryGetExchanges()

    const onClick = () => {
        closeModal && closeModal()
        saveRecentUser({id: id, name: name, thumbnail: thumbnail, is_leader: is_leader, exchange: exchange})
        createLog('event', 'searched_user_click', {user_id: id})
    }
    return (
        <Link href={is_leader ? getLinkUrl(URL_KEY_PORTFOLIO(id)) : getLinkUrl(URL_PORTFOLIO(id))}>
            <div
                className={
                    'px-[15px] py-[10px] space-x-[10px] flex rounded-[5px] items-center cursor-pointer hover:bg-gray09 dark:hover:bg-dark_gray09'
                }
                onClick={onClick}>
                <UserThumbnail src={thumbnail} size={28} />
                <div className={'flex-1 w-full basis-[85%] '}>
                    <Text className={'text-body2 text-gray01 dark:text-dark_gray01 break-all'}>{name}</Text>
                    {is_leader === true && (
                        <div className={'flex gap-x-[5px] items-center'}>
                            <WizImage src={getExchange(exchange)?.icon} width={16} height={16} />
                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>{exchange}</Text>
                        </div>
                    )}
                </div>
                {is_leader === true && <CopyLeaderTag />}
            </div>
        </Link>
    )
}

export default SearchUserItem
